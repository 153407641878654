@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  background-color: #161616;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  font-family: "Inter", sans-serif;
}

:root {
  --primary-color: #fe9b33;
  --secondary-color: #e86726;
  --tertiary-color: #e86726;
  --white-color: #ffffff;
  --black-color: #161616;
}

.primary {
  color: #fe9b33;
}
.secc {
  color: #e86726;
}
.terr {
  color: #e86726;
}
.white {
  color: #ffffff;
}
.black {
  color: #161616;
}

/* Navbar styles */
.NavBar {
  width: 100%;
  position: fixed;
  margin-top: -66px;
  z-index: 10;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: #2d2d2d !important;
  color: white !important;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: white !important;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.NavbarLogo {
  width: 150px;
  height: 40px;
  background-image: url("../assets/NavbarLogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: #e86726 !important;
  background-color: #b14b34 !important;
  border: none !important;
  border-radius: 30px !important;
  transition: var(--bs-navbar-toggler-transition);
}

/* Global Styles */
.websiteContent {
  margin-top: 66px;
}

.smallerHeader {
  width: 100%;
  height: 300px;
  border-bottom: 5px solid #e86726;
  background-image: url("../assets/carter-yocham-JYIoO7xysAU-unsplash.jpg");
  background-size: cover;
  background-position: center;
}

.smallerHeaderImageOverlay {
  width: 100%;
  height: 300px;
  background-color: #16161647;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Home page */

.homeHeader {
  width: 100%;
  height: 600px;
  border-bottom: 5px solid #e86726;
  background-image: url("../assets/carter-yocham-JYIoO7xysAU-unsplash.jpg");
  background-size: cover;
  background-position: center;
}

.homeHeaderImageOverlay {
  width: 100%;
  height: 600px;
  background-color: #16161647;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeServicesSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 120px;
}

.homeServiceCard {
  background-color: #2a2a2a;
  color: white;
  margin-bottom: 20px;
}

.whyChooseUsSection {
  background-color: #3b3b3b;
}

.chooseUsCard {
  background-color: #161616;
  color: white;
  padding: 20px;
  margin: 10px;
  margin-bottom: 10px;
}

/* AboutUs */
.about-container {
  margin-top: 50px;
  max-width: 1000px;
}

.about-card {
  background-color: #393939;
  border: 2px solid #3e3e3e;
  margin-bottom: 20px;
}

/* Services page */
.services-container {
  margin-top: 50px;
}

.service-card {
  margin: 10px;
  background-color: #2d2d2d;
  color: white;
}

/* Contact Us page */
.contact-container {
  max-width: 1100px;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}
